/*eslint-disable*/
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { login } from 'src/services/auth';
import { Autocomplete, Box, Container, TextField, Typography } from '@material-ui/core';
import CustomNotifier from 'src/customComponents/Notifier';
import CustomButton from 'src/customComponents/Buttons';
import { fetchProductMasterData } from 'src/services/product/product';

const Login = () => {
  const [snackBar, setSnackBar] = useState({ open: false, message: '' });
  const [products, setProducts] = useState([]);
  const navigate = useNavigate();

  const handleFormSubmit = async (values) => {
    // const delay = (ms) => new Promise((res) => setTimeout(res, ms));
    // console.log('values:: ', values);
    await login(values.email, values.password, values.product.PRODUCT_ID, values.product.PRODUCT_NAME)
        .then((res) => {
          console.log("in login",res.message);
          if(res.statusCode == 200){
            setSnackBar({ open: true, message: res.message });
            navigate('/app/dashboard');
            return true;
          }else{
            setSnackBar({ open: true, message: res.message });
            return true;
          }
        })
        .catch((error) => {
          setSnackBar({ open: true, message: error });
        });

    // if (values.email == 'admin@hungama.com' && values.password === 'admin') {
    //   setSnackBar({ open: true, message: 'Login Successfull' });
    //   await delay(1500);
    //   navigate('/app/dashboard');
    //   return true;
    // }
    // await delay(500);
    // setSnackBar({ open: false, message: '' });
    // setSnackBar({ open: true, message: 'Invalid Credentials' });
    // return false;
  };

  useEffect(() => {
    fetchProductMasterData('', '', true)
      .then((res) => {
        setProducts(res?.tableData);
      })
      .catch(() => {
        setProducts([
          {
            PRODUCT_ID: 1,
            PRODUCT_CODE: 'hungamaun',
            PRODUCT_NAME: 'Hungama UN'
          }
        ]);
        setSnackBar({ open: true, message: 'Error while fetching products.' });
      });
  }, []);
  return (
    <>
      <Helmet>
        <title>Login | Material Kit</title>
      </Helmet>
      <CustomNotifier setSnackBarOpen={snackBar.open} message={snackBar.message} />
      <Box
        sx={{
          backgroundColor: 'background.default',
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          justifyContent: 'center'
        }}
      >
        <Container maxWidth="sm">
          <Formik
            initialValues={{
              email: '',
              password: '',
              product: {
                PRODUCT_ID: 1,
                PRODUCT_CODE: 'hungamaun',
                PRODUCT_NAME: 'Hungama UN'
              }, // Set default product
            }}
            validationSchema={Yup.object().shape({
              email: Yup.string()
                .email('Must be a valid email')
                .max(255)
                .required('Email is required'),
              password: Yup.string().max(255).min(5).required('Password is required')
              // product: Yup.string().nullable().required('Product is required')
            })}
            onSubmit={handleFormSubmit}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              setFieldValue,
              touched,
              values
            }) => (
              <form onSubmit={handleSubmit}>
                <Box sx={{ mb: 3 }}>
                  <Typography color="textPrimary" variant="h2">
                    Sign in
                  </Typography>
                </Box>
                <Autocomplete
                  options={products}
                  getOptionLabel={(option) => option.PRODUCT_NAME || ''}
                  isOptionEqualToValue={(option, value) => option?.PRODUCT_ID === value?.PRODUCT_ID}
                  name="product"
                  variant="outlined"
                  value={values.product} // Controlled component
                  onChange={(event, value) => {
                    console.log('event:', event, 'value:', value);
                    setFieldValue('product', value);
                  }}
                  renderInput={(params) => (
                    <TextField
                      error={Boolean(touched.product && errors.product)}
                      helperText={touched.product && errors.product}
                      {...params}
                      label="Select Product"
                    />
                  )}
                />
                <TextField
                  error={Boolean(touched.email && errors.email)}
                  fullWidth
                  helperText={touched.email && errors.email}
                  label="Email Address"
                  margin="normal"
                  name="email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="email"
                  value={values.email}
                  variant="outlined"
                />
                <TextField
                  error={Boolean(touched.password && errors.password)}
                  fullWidth
                  helperText={touched.password && errors.password}
                  label="Password"
                  margin="normal"
                  name="password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="password"
                  value={values.password}
                  variant="outlined"
                />
                <Box sx={{ py: 2 }}>
                  <CustomButton
                    disabled={isSubmitting}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    Sign in now
                  </CustomButton>
                </Box>
              </form>
            )}
          </Formik>
        </Container>
      </Box>
    </>
  );
};

export default Login;
