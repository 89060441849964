/* eslint-disable */
import { useEffect, useState } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Autocomplete,
  Avatar,
  Box,
  Divider,
  Drawer,
  Hidden,
  List,
  TextField,
  Typography
} from '@material-ui/core';
import NavItem from './NavItem';
import { routes } from './sidebar/routes';
import { User } from 'react-feather';


const user = {
  // /static/images/avatars/avatar_1.png
  avatar: '',
  jobTitle: 'Hungama Payment',
  name: 'Payadmin'
};

const DashboardSidebar = ({ onMobileClose, openMobile }) => {
  const [userdata, setUserData] = useState([]);
  const location = useLocation();
  const products = [
    { id: 1, PRODUCT_NAME: 'Hungama UN' },
    { id: 2, PRODUCT_NAME: 'HM' }
  ];
  useEffect(() => {
    let userdata= JSON.parse(localStorage.getItem(('user')))
    setUserData(userdata);
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const onProductChange = (e, v) => {
    console.log('product change::', e, v);
  };
  const content = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
      }}
    >
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
          p: 2
        }}
      >
        <Avatar
          component={RouterLink}
          src={user.avatar}
          sx={{
            cursor: 'pointer',
            width: 64,
            height: 64
          }}
          to="/app/account"
        />
        <Typography color="textPrimary" variant="h7">
          {userdata.full_name}
        </Typography>
        <Typography color="textSecondary" variant="body2">
          {userdata.product_name}
        </Typography>
      </Box>
      <Divider />
      <Box sx={{ p: 2 }}>
        {/* select product  */}
        {/* <Autocomplete
          disablePortal
          getOptionLabel={(option) => option.PRODUCT_NAME || ''}
          isOptionEqualToValue={(option, value) => option.value === value.value}
          options={products}
          renderInput={(params) => <TextField {...params} label="Product" />}
          onChange={onProductChange}
        /> */}
        <List>
          {
          routes
          .filter((item) => item.user_type.includes(userdata.user_type))
          .map((item) => (
            <NavItem
              href={item.href}
              key={item.title}
              title={item.title}
              icon={item.icon}
              nestedRoutes={item.nestedRoutes}
            />
        ))}
        </List>
      </Box>
      <Box sx={{ flexGrow: 1 }} />
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
          PaperProps={{
            sx: {
              width: 256
            }
          }}
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden lgDown>
        <Drawer
          anchor="left"
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: 256,
              top: 64,
              height: 'calc(100% - 64px)'
            }
          }}
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

DashboardSidebar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

DashboardSidebar.defaultProps = {
  onMobileClose: () => {},
  openMobile: false
};

export default DashboardSidebar;
