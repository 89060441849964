import axios from 'axios';
import logger from 'src/logger';

const data = {
  pagination: {},
  tableData: [],
  message: ''
};

export const customData = '';

export const getPlanDetailsApi = async (page, limit, productId) => {
  try {
    const getData = await axios.get(
      `${process.env.REACT_APP_API_URL}/plan/details?page=${page}&view=${limit}&product_id=${productId}`
    );
    const response = getData?.data;

    if (response.statusCode !== 200) throw response;

    // logger('planMaster', response);
    const dataTable = response?.data?.plan_details ? response?.data?.plan_details : [];
    const paginationDetails = response?.data?.pagination ? response.data.pagination : {};
    const message = response?.message ? response.message : '';

    data.pagination = paginationDetails;
    data.tableData = dataTable;
    data.message = message;

    return data;
  } catch (error) {
    logger('planDetailsERROR : ', error);
    throw error.message;
  }
};

export const planDetailSearchDataApi = async (page, limit, input, productId) => {
  try {
    const getData = await axios.get(
      `${process.env.REACT_APP_API_URL}/plan/details/search?page=${page}&view=${limit}&input=${input}&product_id=${productId}`
    );
    const response = getData?.data;

    if (response.statusCode !== 200) throw response;

    // logger('planDetailSearch', response);
    const dataTable = response?.data?.plan_details ? response.data.plan_details : [];
    const paginationDetails = response?.data?.pagination ? response.data.pagination : {};
    const message = response?.message ? response.message : '';
    // console.log('datatable', dataTable);
    data.pagination = paginationDetails;
    data.tableData = dataTable;
    data.message = message;

    return data;
  } catch (error) {
    logger('planDetailsSearchError : ', error);
    throw error.message;
  }
};

export const addPlanDetailApi = async (body) => {
  try {
    const getData = await axios.post(
      `${process.env.REACT_APP_API_URL}/plan/details`,
      body
    );
    // console.log('getData', getData);
    const response = getData?.data;
    // console.log('response', response);
    if (response.statusCode !== 200) throw response;

    // logger('addPlatform', response);
    return response;
  } catch (error) {
    // logger('addPlatformERROR : ', error);
    throw error.message;
  }
};

export const updatePlanDetailApi = async ({
  PLAN_DETAILS_ID,
  PLAN_ID,
  PRODUCT_ID,
  PLATFORM_ID,
  PAYMENT_ID,
  PLAN_CAPTION,
  PLAN_COUNTRY,
  PLAN_CURRENCY,
  PLAN_CURRENCY_SYMBOL,
  PLAN_PRICE,
  ORIGINAL_PRICE,
  RENEWAL_PRICE,
  PLAN_TYPE,
  IS_AUTO_RENEWAL,
  PLAN_DURATION,
  PLAN_DOWNLOADS,
  PLAN_DETAILS,
  STATUS,
  STORE_PAYMENT_ID,
  MULTI_PRICE,
  COINS,
  OFFER_TEXT,
  OFFER_TEXT_DETAILS,
  IS_FEATURED,
  IS_BANNER_PRICE,
  CONTENT_EVENT_MAPPING,
  PLAN_IDENTIFIER,
  ABOUT_PLAN,
  DISPLAY,
  FREE_TRIAL_DAYS,
  PLAN_TITLE,
  PLAN_BENEFITS,
  SHOW_ADS,
  ADDED_BY
}) => {
  try {
    const getData = await axios.put(
      `${process.env.REACT_APP_API_URL}/plan/details/update/${PLAN_DETAILS_ID}`,
      {
        PLAN_ID,
        PRODUCT_ID,
        PLATFORM_ID,
        PAYMENT_ID,
        PLAN_CAPTION,
        PLAN_COUNTRY,
        PLAN_CURRENCY,
        PLAN_CURRENCY_SYMBOL,
        PLAN_PRICE,
        ORIGINAL_PRICE,
        RENEWAL_PRICE,
        PLAN_TYPE,
        IS_AUTO_RENEWAL,
        PLAN_DURATION,
        PLAN_DOWNLOADS,
        PLAN_DETAILS,
        STATUS,
        STORE_PAYMENT_ID,
        MULTI_PRICE,
        COINS,
        OFFER_TEXT,
        OFFER_TEXT_DETAILS,
        IS_FEATURED,
        IS_BANNER_PRICE,
        CONTENT_EVENT_MAPPING,
        PLAN_IDENTIFIER,
        ABOUT_PLAN,
        DISPLAY,
        FREE_TRIAL_DAYS,
        PLAN_TITLE,
        PLAN_BENEFITS,
        SHOW_ADS,
        ADDED_BY
      }
    );
    // console.log('getData', getData);
    const response = getData?.data;
    // console.log('response', response);
    if (response.statusCode !== 200) throw response;

    // logger('addPlatform', response);
    return response;
  } catch (error) {
    // logger('addPlatformERROR : ', error);
    throw error.message;
  }
};

export const getPlanDetailsByIdApi = async (planDetailId) => {
  try {
    const getData = await axios.get(
      `${process.env.REACT_APP_API_URL}/plan/details/${planDetailId}`
    );
    const response = getData?.data;

    if (response.statusCode !== 200) throw response;

    // logger('planMaster', response);
    const dataTable = response?.data?.plan_details ? response?.data?.plan_details : [];
    // const paginationDetails = response?.data?.pagination ? response.data.pagination : {};
    const message = response?.message ? response.message : '';

    // data.pagination = paginationDetails;
    data.tableData = dataTable;
    data.message = message;

    return data;
  } catch (error) {
    // logger('getplanDetailsByIdERROR : ', error);
    throw error.message;
  }
};

export const removePlanDetailsByIdApi = async (planDetailId) => {
  try {
    const getData = await axios.delete(
      `${process.env.REACT_APP_API_URL}/plan/details/${planDetailId}`
    );
    const response = getData?.data;

    if (response.statusCode !== 200) throw response;

    // logger('planMaster', response);
    const dataTable = response?.data?.plan_details ? response?.data?.plan_details : [];
    // const paginationDetails = response?.data?.pagination ? response.data.pagination : {};
    const message = response?.message ? response.message : '';

    // data.pagination = paginationDetails;
    data.tableData = dataTable;
    data.message = message;

    return data;
  } catch (error) {
    // logger('getplanDetailsByIdERROR : ', error);
    throw error.message;
  }
};
