import axios from 'axios';
import logger from 'src/logger';

const data = {
  pagination: {},
  tableData: [],
  message: ''
};

export const fetchUserOrders = async (page, limit, PRODUCT_ID) => {
  try {
    const getData = await axios.get(
      `${process.env.REACT_APP_API_URL}/order?page=${page}&view=${limit}&product_id=${PRODUCT_ID}`
    );
    const response = getData?.data;

    if (response.statusCode !== 200) throw response;

    logger('allOrder', response);
    const userDetails = response?.data?.userDetails;
    const dataTable = response?.data?.user_orders ? response.data.user_orders : [];
    const paginationDetails = response?.data?.pagination ? response.data.pagination : {};
    const message = response?.message ? response.message : '';

    data.pagination = paginationDetails;
    data.tableData = dataTable;
    data.userDetails = userDetails;
    data.message = message;

    return data;
  } catch (error) {
    logger('allOrderERROR : ', error);
    throw error.message;
  }
};

export const fetchSearchData = async (page, limit, input, PRODUCT_ID) => {
  try {
    const getData = await axios.post(
      `${process.env.REACT_APP_API_URL}/order/search?page=${page}&view=${limit}&input=${input}&product_id=${PRODUCT_ID}`
    );
    const response = getData?.data;

    if (response.statusCode !== 200) throw response;

    logger('searchOrder', response);
    const userSearchOrder = response?.data?.user_search_orders
      ? response.data.user_search_orders
      : [];
    const userDetails = response?.data?.userDetails;
    const paginationDetails = response?.data?.pagination ? response.data.pagination : {};
    const message = response?.message ? response.message : '';

    data.pagination = paginationDetails;
    data.tableData = userSearchOrder;
    data.userDetails = userDetails;
    data.message = message;

    return data;
  } catch (error) {
    logger('searchOrderERROR : ', error);
    throw error.message;
  }
};

export const fetchSingleOrderById = async (id) => {
  try {
    const getData = await axios.get(`${process.env.REACT_APP_API_URL}/order/${id}`);
    console.log('getData', getData);
    const response = getData?.data;
    console.log('response', response);
    if (response.statusCode !== 200) throw response;

    logger('fetchSingleOrder', response);
    return response;
  } catch (error) {
    logger('fetchSingleOrderERROR : ', error);
    throw error.message;
  }
};

export const updateAssignSubscriptionApi = async (formData) => {
  try {
    const getData = await axios.post(
      `${process.env.REACT_APP_API_URL}/order/subscription/update-assign`,
      formData
    );
    // console.log('getData', getData);
    const response = getData?.data;
    // console.log('response', response);
    if (response.statusCode !== 200) throw response;

    logger('updateAssign Subscription', response);
    return response;
  } catch (error) {
    logger('updateAssign Subscription Error : ', error);
    throw error.message;
  }
};

export const updateOrderApi = async (fields) => {
  try {
    const getData = await axios.put(
      `${process.env.REACT_APP_API_URL}/order/update`,
      fields
    );
    // console.log('getData', getData);
    const response = getData?.data;
    // console.log('response', response);
    if (response.statusCode !== 200) throw response;

    // logger('updateOrder', response);
    return response;
  } catch (error) {
    // logger('updateorderERROR : ', error);
    throw error.message;
  }
};
export const removeOrderByIdApi = async (id) => {
  try {
    const getData = await axios.delete(`${process.env.REACT_APP_API_URL}/order/${id}`);
    const response = getData?.data;

    if (response.statusCode !== 200) throw response;

    // logger('planMaster', response);
    const dataTable = response?.data?.order_master ? response.data.order_master : [];
    const paginationDetails = response?.data?.pagination ? response.data.pagination : {};
    const message = response?.message ? response.message : '';

    data.pagination = paginationDetails;
    data.tableData = dataTable;
    data.message = message;

    return data;
  } catch (error) {
    // logger('remOrderERROR : ', error);
    throw error.message;
  }
};
