import axios from 'axios';
// import logger from 'src/logger';

const data = {
  pagination: {},
  tableData: [],
  message: ''
};

export const getPlanSequenceApi = async (page, limit, productId) => {
  try {
    const getData = await axios.get(
      `${process.env.REACT_APP_API_URL}/plan/sequence?page=${page}&view=${limit}&product_id=${productId}`
    );
    const response = getData?.data;

    if (response.statusCode !== 200) throw response;

    // logger('planSequence', response);
    const dataTable = response?.data?.plan_sequence ? response.data.plan_sequence : [];
    const paginationDetails = response?.data?.pagination ? response.data.pagination : {};
    const message = response?.message ? response.message : '';

    data.pagination = paginationDetails;
    data.tableData = dataTable;
    data.message = message;

    return data;
  } catch (error) {
    // logger('planSequenceERROR : ', error);
    throw error.message;
  }
};

export const getPlanSequenceByIdApi = async (id) => {
  try {
    const getData = await axios.get(
      `${process.env.REACT_APP_API_URL}/plan/sequence/${id}`
    );
    const response = getData?.data;

    if (response.statusCode !== 200) throw response;

    // logger('planSequence', response);
    const dataTable = response?.data?.plan_sequence ? response.data.plan_sequence : [];
    const paginationDetails = response?.data?.pagination ? response.data.pagination : {};
    const message = response?.message ? response.message : '';

    data.pagination = paginationDetails;
    data.tableData = dataTable;
    data.message = message;

    return data;
  } catch (error) {
    // logger('planSequenceERROR : ', error);
    throw error.message;
  }
};

export const getPlanSequenceSearchApi = async (page, limit, input, productId) => {
  try {
    const getData = await axios.get(
      `${process.env.REACT_APP_API_URL}/plan/sequence/search?page=${page}&view=${limit}&input=${input}&product_id=${productId}`
    );
    const response = getData?.data;

    if (response.statusCode !== 200) throw response;

    // logger('planSequence', response);
    const dataTable = response?.data?.plan_sequence ? response.data.plan_sequence : [];
    const paginationDetails = response?.data?.pagination ? response.data.pagination : {};
    const message = response?.message ? response.message : '';
    data.pagination = paginationDetails;
    data.tableData = dataTable;
    data.message = message;

    return data;
  } catch (error) {
    // logger('paymentPlatformERROR : ', error);
    throw error.message;
  }
};
export const addPlanSequenceApi = async ({
  PRODUCT_ID,
  PLAN_ID,
  SEQUENCE,
  STATUS,
  PLATFORM_ID
}) => {
  try {
    const getData = await axios.post(`${process.env.REACT_APP_API_URL}/plan/sequence`, {
      PRODUCT_ID,
      PLAN_ID,
      SEQUENCE,
      STATUS,
      PLATFORM_ID
    });
    // console.log('getData', getData);
    const response = getData?.data;
    // console.log('response', response);
    if (response.statusCode !== 200) throw response;

    // logger('addPlatform', response);
    return response;
  } catch (error) {
    // logger('addPlatformERROR : ', error);
    throw error.message;
  }
};

export const updatePlanSequenceApi = async ({
  PLAN_SEQ_ID,
  PRODUCT_ID,
  PLAN_ID,
  SEQUENCE,
  STATUS,
  PLATFORM_ID
}) => {
  try {
    const getData = await axios.put(
      `${process.env.REACT_APP_API_URL}/plan/sequence/update`,
      {
        PLAN_SEQ_ID,
        PRODUCT_ID,
        PLAN_ID,
        SEQUENCE,
        STATUS,
        PLATFORM_ID
      }
    );
    // console.log('getData', getData);
    const response = getData?.data;
    // console.log('response', response);
    if (response.statusCode !== 200) throw response;

    // logger('addPlatform', response);
    return response;
  } catch (error) {
    // logger('addPlatformERROR : ', error);
    throw error.message;
  }
};

export const removePlanSequenceByIdApi = async (id) => {
  try {
    const getData = await axios.delete(
      `${process.env.REACT_APP_API_URL}/plan/sequence/${id}`
    );
    const response = getData?.data;

    if (response.statusCode !== 200) throw response;

    // logger('planSequence', response);
    const dataTable = response?.data?.plan_sequence ? response.data.plan_sequence : [];
    const paginationDetails = response?.data?.pagination ? response.data.pagination : {};
    const message = response?.message ? response.message : '';

    data.pagination = paginationDetails;
    data.tableData = dataTable;
    data.message = message;

    return data;
  } catch (error) {
    // logger('planSequenceERROR : ', error);
    throw error.message;
  }
};

export const customData = '';
